export const resumeListEn = [
  // {
  //   id: 1,
  //   sectionTitle: "Summary",
  //   content: [
  //     {
  //       title: "Inga Daujotė",
  //       description:
  //         "Full stack developer, 2 years experience in JavaScript, CSS (and SCSS), React, Node.js, MySql",
  //       date: "2023 May",
  //       list: [
  //         { item: "https://www.linkedin.com/in/inga-daujote/" },
  //         { item: "https://github.com/IngaGd" },
  //       ],
  //     },
  //   ],
  // },
  {
    id: 2,
    sectionTitle: "Academies",
    content: [
      {
        title: "Vinted",
        description: "WEB Engineer",
        date: "2024 May - July",
        list: [
          {
            item: "Programming using TypeScript, React, Jest, SCSS, and Git technologies",
          },
          {
            item: "Team project - implementing new functionality on the platform",
          },
          {
            item: "Work planning with Jira following Agile methodology",
          },
          {
            item: "Peer review of collegue's code",
          },
          { item: "Experience using WebUI" },
          { item: "Creating new components and reusing existing ones" },
        ],
      },
      {
        title: "Sourcery",
        description: "Front End",
        date: "2024 March - April",
        list: [
          { item: "Teamwork using the Bitbucket platform" },
          { item: "Work planning with Jira following Agile methodology" },
          { item: "Team project - creating a food ordering application" },
          { item: "CSS/SCSS, JavaScript, TypeScript, React workshops" },
        ],
      },
    ],
  },
  {
    id: 3,
    sectionTitle: "Professional experience",
    content: [
      {
        title: "Freelancer",
        description: "Clients: Palms' Studio, MB Maistas",
        date: "2023 July - Now",
        list: [
          {
            item: "Client Relationship Management",
          },
          {
            item: "End-to-End Web Development",
          },
          {
            item: "Database Management and Integration",
          },
          {
            item: "Web Design and Styling",
          },
          { item: "Deployment and Hosting" },
        ],
      },
      {
        title:
          "Purchasing manager, Supply manager, Transport manager, Manager assistant",
        description: "Several different companies, Lithuania",
        date: "2004 September - 2021 November",
        // list: [
        //   { item: "Building relationship with business partners" },
        // {
        //   item: "Supply chain management, ensuring best terms and conditions (negotiations) ",
        // },
        // {
        //   item: "Deep knowledge of the range of materials (technical requirements, market)",
        // },
        // { item: "Mediation in product development" },
        // {
        //   item: "Execution, analysis and improvement of day-to-day activities and QA",
        // },
        // ],
      },
    ],
  },
  {
    id: 4,
    sectionTitle: "Bootcamps",
    content: [
      {
        title: "Javascript",
        description: "Baltic institute of technology, Lithuania",
        date: "2022 November - 2023 April",
        list: [
          {
            item: "Proficiency in HTML, CSS, and SCSS styling, with a focus on creating responsive design.",
          },
          {
            item: "In-depth understanding of JavaScript, including practical experience in working with arrays, strings, objects, and DOM manipulation.",
          },
          {
            item: "Extensive experience with React, covering core concepts, context, hooks, and CRUD operations.",
          },
          {
            item: "Utilising Express to implement HTTP methods such as GET, POST, PUT, and DELETE.",
          },
          {
            item: "Leveraging MariaDB platform for MySQL database operations.",
          },
        ],
      },
      {
        title: "Java",
        description: "WomenGoTech, Accenture",
        date: "2022 July - October",
        list: [
          {
            item: "Fundamentals: variables, data types, math/logic operations, and date/time management. Code branching and looping, and use of RegExp.",
          },
          {
            item: "Working with arrays, strings, classes, and methods in Java.",
          },
          {
            item: "Understanding of Object-Oriented Programming (OOP) concepts.",
          },
          {
            item: "Experience in utilising collections and maps for data management, and familiarity with (UML).",
          },
          {
            item: "SQL database (basics, data grouping, database management, functions, procedures, triggers, joins, relational database, transactions).",
          },
        ],
      },
    ],
  },
  {
    id: 5,
    sectionTitle: "Education",
    content: [
      {
        title: "Master of business economics",
        description: "Vilnius university, Lithuania",
        date: "2011 - 2014",
        list: [],
      },
      {
        title: "Bachelor of mathematics",
        description: "Vilnius university, Lithuania",
        date: "2000 - 2004",
        list: [],
      },
    ],
  },
];

export const resumeListLt = [
  // {
  //   id: 1,
  //   sectionTitle: "Gyvenimo Aprašymas",
  //   content: [
  //     {
  //       title: "Inga Daujotė",
  //       description:
  //         '"Full stack" programuotoja, 2 metų patirtis su JavaScript, CSS (and SASS), React, Node.js, MySql',
  //       date: "2023 Balandis",
  //       list: [
  //         { item: "https://www.linkedin.com/in/inga-daujote/" },
  //         { item: "https://github.com/IngaGd" },
  //       ],
  //     },
  //   ],
  // },
  {
    id: 2,
    sectionTitle: "Akademijos",
    content: [
      {
        title: "Vinted",
        description: "",
        date: "2024 Gegužė - Liepa",
        list: [
          {
            item: "Darbas naudojant TypeScript, React, Jest, SCSS, Git technologijas",
          },
          {
            item: "Komandinis projektas - naujo funkcionalumo platformoje diegimas",
          },
          {
            item: "Darbo planavimas su Jira pagal Agile metodologiją",
          },
          {
            item: "Kolegų kodo analizė, vertinimas ir komentavimas",
          },
          {
            item: "Patirtis naudojant WebUI",
          },
          { item: "Naujų koponentų kūrimas ir esamų perpanaudojimas" },
        ],
      },
      {
        title: "Front-End",
        description: "Sourcery Academy",
        date: "2024 Kovas - Balandis",
        list: [
          {
            item: "Darbas su Bitbucket platforma",
          },
          {
            item: "Darbo planavimas su Jira naudojantis Agile metodologiją",
          },
          {
            item: "Komandinis projektas - maisto užsakymų aplikacijos kūrimas",
          },
          {
            item: "CSS/SCSS, JavaScript, TypeScript, React dirbtuvės",
          },
        ],
      },
    ],
  },
  {
    id: 3,
    sectionTitle: "Darbo Patirtis",
    content: [
      {
        title: "Individuali veikla",
        description: "Klientas: Palm studio, MB Maistas",
        date: "2023 Liepa - dabar",
        list: [
          {
            item: "Santykiu su klientais valdymas",
          },
          {
            item: "End-to-End Web programavimas",
          },
          {
            item: "Duomenų bazių valdymas bei integracija",
          },
          {
            item: "Web dizainas ir stilius",
          },
          { item: "Nuo-iki diegimas ir palaikymas" },
        ],
      },
      {
        title:
          "Pirkimų vadybininkė, Tiekimo vadybininkė, Transporto vadybininkė, Vadybininko asistentė",
        description: "Kelios skirtingos kompanijos, Lietuva",
        date: "2004 Rugsėjis - 2021 Lapkritis",
        // list: [
        //   {
        //     item: "Ryšių su verslo partneriais užmezgimas, vystymas ir palaikymas.",
        //   },
        //   {
        //     item: "Tiekimo grandinės valdymas - geriausių sąlygų bei kainų užtikrinimas (derybos) ",
        //   },
        //   {
        //     item: "Gilus medžiagų, techninių reikalavimų  ir rinkos išmanymas",
        //   },
        //   { item: "Naujo produkto įvedimo projekto vadovavimas" },
        //   {
        //     item: "Kasdienės veiklos vykdymas, analizė ir tobulinimas bei kokybės užtikrinimas (QA)",
        //   },
        // ],
      },
    ],
  },
  {
    id: 4,
    sectionTitle: "Intensyvi mokymo programa (Bootcamp)",
    content: [
      {
        title: "Javascript",
        description: "Baltijos technologijų institutas, Vilnius",
        date: "2022 Lapkritis - 2023 Balandis",
        list: [
          {
            item: "Gilios HTML, CSS, SCSS žinios su fokusu į adaptyvų dizainą.",
          },
          {
            item: "Gilios JavaScript žinios su praktine darbo su masyvais, eilutėmis, objektais ir DOM manipuliacijos.",
          },
          {
            item: "React žinios, apimančios pagrindines koncepcijas, kontekstus ir CRUD operacijas.",
          },
          {
            item: "HTTP metodų GET, POST, PUT, DELETE implementacija naudojant Express programų sistemą.",
          },
          {
            item: "MariaDB platformos išnaudojimas MySQL duomenų bazių operacijoms.",
          },
        ],
      },
      {
        title: "Java",
        description: "WomenGoTech, Accenture",
        date: "2022 Liepa - Spalis",
        list: [
          {
            item: "Fundamentai: kintamieji, duomenų tipai matematinės/loginės operacijos, datos laiko manipuliacijos. Kodo šakojimas ir kilpos bei RegExp naudojimas.",
          },
          {
            item: "Darbas su masyvais, eilutėmis ir Java metodais.",
          },
          {
            item: "Objektinio programavimo suvokimas konceptualiai.",
          },
          {
            item: "Patirtis naudojant kolekcijas ir žemėlapius duomenų valdymui, bei susipažinimas su UML.",
          },
          {
            item: "Darbas su SQL duomenų baze.",
          },
        ],
      },
    ],
  },
  {
    id: 5,
    sectionTitle: "Išsilavinimas",
    content: [
      {
        title: "Verslo ekonomikos Magistras",
        description: "Vilniaus universitetas, Lietuva",
        date: "2011 - 2014",
        list: [],
      },
      {
        title: "Matematikos bakalauras",
        description: "Vilniaus universitetas, Lietuva",
        date: "2000 - 2004",
        list: [],
      },
    ],
  },
];
